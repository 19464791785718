<template>
  <v-form
    @submit.prevent="save"
    ref="form"
    lazy-validation
    v-model="valid"
    v-if="user"
  >
    <v-row dense>
      <v-col cols="12" sm="4">
        <v-text-field
          id="name"
          name="name"
          label="Nome Completo"
          outlined
          dense
          required
          :rules="[required]"
          v-model="user.name"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          id="email"
          name="email"
          label="Informe seu email"
          outlined
          dense
          required
          :rules="[required, email]"
          v-model="user.email"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          id="document"
          name="document"
          label="CPF"
          dense
          outlined
          required
          :rules="[required, cpf]"
          v-mask="'###.###.###-##'"
          v-model="user.document"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          id="phoneNumber"
          name="phoneNumber"
          label="Telefone/Celular"
          outlined
          dense
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="user.phoneNumber"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <BaseProfile
          id="profile"
          name="profile"
          label="Perfil"
          required
          :rules="[required]"
          v-model="user.profile"
          @change="clearFields"
        />
      </v-col>
      <v-col cols="12" sm="4" v-if="user.profile">
        <BaseRoles
          id="role"
          name="role"
          label="Permissões"
          required
          :rules="[required]"
          :profile="user.profile"
          v-model="user.role"
        />
      </v-col>
      <template v-if="user.profile && user.profile !== 'Administrador'">
        <v-col cols="12" sm="4" v-if="checkPermission(['CC1', 'CC2'], '!==')">
          <BaseBusinessUnit
            id="businessUnitId"
            name="businessUnitId"
            label="Unidades de negócio"
            required
            :rules="[required]"
            v-model="user.businessUnitId"
          />
        </v-col>
        <v-col
          cols="12"
          sm="8"
          v-if="user.businessUnitId"
          v-permission="{ roles: ['CC1', 'CC2'], operator: '!==' }"
        >
          <BaseCustomerByBusinessUnit
            id="customers"
            name="customers"
            label="Clientes"
            required
            :rules="[requiredLength]"
            :profile="user.profile"
            :businessUnit="user.businessUnitId"
            v-model="user.customers"
          />
        </v-col>
      </template>

      <v-col cols="12" sm="4" v-if="checkPermission(['CC1'], '===')">
        <BaseCustomersByCustomerManager
          id="customers"
          name="customers"
          label="Clientes"
          required
          :rules="[requiredLength]"
          v-model="user.customers"
        />
      </v-col>

      <v-col cols="12" xl="3" lg="3" md="3" sm="3" v-if="edit">
        <v-autocomplete
          label="Status"
          id="situation"
          name="situation"
          dense
          outlined
          :items="situation"
          v-model="user.situation"
        />
      </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <v-row v-permission="{ roles: ['A1', 'CC1'], operator: '===' }">
      <v-col cols="12" class="text-right">
        <BaseButton
          id="btn-save"
          name="btn-save"
          type="submit"
          color="primary"
          title="Salvar"
          :disabled="!valid"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { UserService } from '@/services/api/user';
import { checkPermission } from '@/helpers/permission';

export default {
  mixins: [rulesMixin],

  props: {
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    userId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    situation: ['Ativo', 'Inativo', 'Bloqueado', 'Primeiro Acesso'],
    user: {
      document: null,
      email: null,
      name: null,
      phoneNumber: null,
      situation: null,
      profile: null,
      role: null,
      businessUnitId: null,
      customers: []
    }
  }),

  mounted() {
    if (this.edit) {
      this.search();
    }
  },

  methods: {
    checkPermission,

    async search() {
      try {
        const userService = new UserService();
        const { status, data } = await userService.getById(this.userId);

        if (status === 200) {
          this.user = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    save() {
      if (!this.$refs.form.validate(true)) return;

      const params = Object.assign({}, this.user);
      params.phoneNumber = unmask(params.phoneNumber);
      params.document = unmask(params.document);

      if (this.edit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const userService = new UserService();
        const { status } = await userService.postUser(params);

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$emit('closeModal', true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const userService = new UserService();
        const { status } = await userService.putUser(params);

        if (status === 200) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$emit('closeModal', true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    clearFields() {
      this.user.role = null;
      this.user.businessUnitId = null;
      this.user.customers = [];

      this.$refs.form.resetValidation();
    }
  }
};
</script>
